import { ColumnDef } from "@tanstack/react-table";
import { Attachment } from "@/types/assetInventory/assets/general";
import { TrashIcon } from "@/assets";

export const getAssetDocumentsColumns = ({
  handleDeleteAssetDocument,
}: {
  handleDeleteAssetDocument: (id: string) => void;
}): ColumnDef<Attachment>[] => {
  return [
    {
      header: "Document Name",
      accessorKey: "name",
      enableSorting: true,
    },
    {
      header: "Document Type",
      accessorKey: "contentType",
    },
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({
        row: {
          original: { id },
        },
      }) => {
        return (
          <button
            className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-red-primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteAssetDocument(id);
            }}
          >
            <TrashIcon fill="white" />
          </button>
        );
      },
    },
  ];
};
