import { FC, InputHTMLAttributes, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { Input } from ".";

interface ControlledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClasses?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  endAdornment?: ReactNode | string;
  endAdornmentClasses?: string;
  inputClasses?: string;
  label?: string;
  name: string;
  required?: boolean;
  startAdornment?: ReactNode | string;
  startAdornmentClasses?: string;
}

export const ControlledInput: FC<ControlledInputProps> = ({
  containerClasses,
  control,
  defaultValue,
  endAdornment,
  endAdornmentClasses,
  inputClasses,
  label,
  onChange,
  name,
  onClick,
  required,
  startAdornment,
  startAdornmentClasses,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        onChange,
      }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          error={error}
          onClick={onClick}
          label={label}
          containerClasses={containerClasses}
          endAdornment={endAdornment}
          endAdornmentClasses={endAdornmentClasses}
          startAdornment={startAdornment}
          startAdornmentClasses={startAdornmentClasses}
          inputClasses={inputClasses}
          name={name}
          id={name}
          {...rest}
        />
      )}
    />
  );
};
