/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONLogic: { input: any; output: any; }
};

export type AddressInput = {
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJobDocumentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  contentType: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isMobileViewable?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJobInput = {
  address?: InputMaybe<AddressInput>;
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  branchId?: InputMaybe<Scalars['String']['input']>;
  crew?: InputMaybe<Array<Scalars['String']['input']>>;
  customer?: InputMaybe<CustomerInput>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  projectOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  salesPerson?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['Date']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<Array<CreateTaskInput>>;
};

export type CreatePriceBookItemInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  branchIds?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Scalars['String']['input']>;
  defaultValue?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  divisionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  rate: Scalars['Float']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  unit: Scalars['String']['input'];
};

export type CreateTaskInput = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  crew?: InputMaybe<Array<Scalars['String']['input']>>;
  date: Scalars['Date']['input'];
};

export type CustomerInput = {
  contactName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum CustomerTypeEnum {
  Company = 'company',
  Individual = 'individual'
}

export type DeleteJobDocumentResponse = {
  __typename?: 'DeleteJobDocumentResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export type DeleteJobResponse = {
  __typename?: 'DeleteJobResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export type DeletePriceBookItemResponse = {
  __typename?: 'DeletePriceBookItemResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export type FormFieldAttributeRuleHook = FormRuleHook & {
  __typename?: 'FormFieldAttributeRuleHook';
  attribute: Scalars['String']['output'];
  dependsOnHooks?: Maybe<Array<Scalars['String']['output']>>;
  hookName: Scalars['String']['output'];
  ruleName: Scalars['String']['output'];
};

export type FormFieldAttributeRuleHookInput = {
  attribute: Scalars['String']['input'];
  dependsOnHooks?: InputMaybe<Array<Scalars['String']['input']>>;
  hookName: Scalars['String']['input'];
  ruleName: Scalars['String']['input'];
};

export type FormFieldValueRuleHook = FormRuleHook & {
  __typename?: 'FormFieldValueRuleHook';
  dependsOnHooks?: Maybe<Array<Scalars['String']['output']>>;
  fieldId?: Maybe<Scalars['String']['output']>;
  hookName: Scalars['String']['output'];
  ruleName: Scalars['String']['output'];
};

export type FormFieldValueRuleHookInput = {
  dependsOnHooks?: InputMaybe<Array<Scalars['String']['input']>>;
  /** fieldId is optional, assumes the fieldId of the field the ruleHook is attached to if not provided */
  fieldId?: InputMaybe<Scalars['String']['input']>;
  hookName: Scalars['String']['input'];
  ruleName: Scalars['String']['input'];
};

export enum FormPdfConfigFieldFormat {
  Boolean = 'BOOLEAN',
  BranchId = 'BRANCH_ID',
  CrewMembers = 'CREW_MEMBERS',
  Date = 'DATE',
  FormSubmissionId = 'FORM_SUBMISSION_ID',
  OccupationId = 'OCCUPATION_ID',
  String = 'STRING',
  UserId = 'USER_ID'
}

export type FormRuleHook = {
  dependsOnHooks?: Maybe<Array<Scalars['String']['output']>>;
  hookName: Scalars['String']['output'];
  ruleName: Scalars['String']['output'];
};

export type FormRuleHookInput = {
  formFieldAttributeRuleHook?: InputMaybe<FormFieldAttributeRuleHookInput>;
  formFieldValueRuleHook?: InputMaybe<FormFieldValueRuleHookInput>;
};

export type FormRuleHookUnion = FormFieldAttributeRuleHook | FormFieldValueRuleHook;

export type FormTemplateRule = {
  __typename?: 'FormTemplateRule';
  name: Scalars['String']['output'];
  rule: Scalars['JSONLogic']['output'];
};

export type FormTemplateRuleInput = {
  name: Scalars['String']['input'];
  rule: Scalars['JSONLogic']['input'];
};

export type GqlAddress = {
  __typename?: 'GqlAddress';
  formattedAddress?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GqlAssetSystemCheck = {
  __typename?: 'GqlAssetSystemCheck';
  environment: Scalars['JSON']['output'];
  featureFlags?: Maybe<Scalars['JSON']['output']>;
  launchDarklyStatus?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  sqlStatus?: Maybe<Scalars['String']['output']>;
};

export type GqlCreateFormSubmissionInput = {
  fields: Array<GqlFormSubmissionFieldInput>;
  formId: Scalars['ID']['input'];
  /** The localDateTime must be passed as a string, otherwise GraphQL will convert to UTC. We want to parse out a timezone offset ourselves. */
  localDateTime: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

export type GqlCustomer = {
  __typename?: 'GqlCustomer';
  contactName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CustomerTypeEnum>;
};

export type GqlField = {
  __typename?: 'GqlField';
  /** The id defined in the formTemplate for this field. Stored as formFieldId in the database. */
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['JSON']['output']>;
};

export enum GqlFormFieldValueTypeEnum {
  Array = 'ARRAY',
  Bool = 'BOOL',
  Number = 'NUMBER',
  String = 'STRING'
}

export type GqlFormPdfConfig = {
  __typename?: 'GqlFormPDFConfig';
  condition?: Maybe<Scalars['String']['output']>;
  cssTemplate?: Maybe<Scalars['String']['output']>;
  fields: Array<GqlFormPdfConfigField>;
  formId: Scalars['String']['output'];
  hbsTemplate: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type GqlFormPdfConfigField = {
  __typename?: 'GqlFormPDFConfigField';
  fieldId: Scalars['String']['output'];
  fields?: Maybe<Array<Maybe<GqlFormPdfConfigField>>>;
  format: FormPdfConfigFieldFormat;
  label: Scalars['String']['output'];
};

export type GqlFormPdfConfigFieldInput = {
  fieldId: Scalars['String']['input'];
  fields?: InputMaybe<Array<InputMaybe<GqlFormPdfConfigFieldInput>>>;
  format: FormPdfConfigFieldFormat;
  label: Scalars['String']['input'];
};

export type GqlFormPdfConfigInput = {
  condition?: InputMaybe<Scalars['String']['input']>;
  cssTemplate?: InputMaybe<Scalars['String']['input']>;
  fields: Array<GqlFormPdfConfigFieldInput>;
  formId: Scalars['String']['input'];
  hbsTemplate: Scalars['String']['input'];
  versionImpact: GqlFormPdfConfigVersionImpact;
};

export type GqlFormPdfConfigResponse = {
  __typename?: 'GqlFormPDFConfigResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  pdfConfig?: Maybe<GqlFormPdfConfig>;
  success: Scalars['Boolean']['output'];
};

export enum GqlFormPdfConfigVersionImpact {
  Major = 'MAJOR',
  Minor = 'MINOR',
  Patch = 'PATCH'
}

export enum GqlFormStatusEnum {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Issue = 'ISSUE',
  New = 'NEW',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export type GqlFormSubmission = {
  __typename?: 'GqlFormSubmission';
  accountId: Scalars['ID']['output'];
  adminNotes?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<GqlFormSubmissionData>;
  edited: Scalars['Boolean']['output'];
  fields: Array<GqlField>;
  formId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  localDateTime: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  pdfFilePath?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use new enum status once available */
  status?: Maybe<Scalars['String']['output']>;
  submitDateTime: Scalars['DateTime']['output'];
  timezoneOffset?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
  version: Scalars['String']['output'];
};

export type GqlFormSubmissionData = {
  __typename?: 'GqlFormSubmissionData';
  adminNotes?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type GqlFormSubmissionFieldInput = {
  /** The id defined in the formTemplate for this field. Stored as formFieldId in the database. */
  id: Scalars['ID']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type GqlFormSubmissionResponse = {
  __typename?: 'GqlFormSubmissionResponse';
  code: Scalars['Int']['output'];
  formSubmission?: Maybe<GqlFormSubmission>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GqlFormTemplate = {
  __typename?: 'GqlFormTemplate';
  accountId: Scalars['String']['output'];
  alerts?: Maybe<Array<GqlFormTemplateAlert>>;
  androidLayout?: Maybe<GqlFormTemplateLayout>;
  createdAt: Scalars['DateTime']['output'];
  divisionId?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<GqlFormTemplateField>>;
  flagForReview?: Maybe<Scalars['Boolean']['output']>;
  formVersion: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logicRules?: Maybe<Array<FormTemplateRule>>;
  name: Scalars['String']['output'];
  parameters?: Maybe<GqlFormTemplateParameters>;
  renderEngineVersion?: Maybe<Scalars['String']['output']>;
  subForms?: Maybe<Array<GqlSubFormTemplate>>;
  submitted?: Maybe<Scalars['Boolean']['output']>;
  templateVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  webLayout?: Maybe<GqlFormTemplateLayout>;
};

export type GqlFormTemplateAlert = {
  __typename?: 'GqlFormTemplateAlert';
  action?: Maybe<Scalars['String']['output']>;
  actionButton?: Maybe<Scalars['String']['output']>;
  cancelButton?: Maybe<Scalars['String']['output']>;
  hasTextField?: Maybe<Scalars['Boolean']['output']>;
  keyboard?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  textField?: Maybe<Scalars['String']['output']>;
  textFieldInputId?: Maybe<Scalars['String']['output']>;
  textFieldPrompt?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type GqlFormTemplateAlertInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actionButton?: InputMaybe<Scalars['String']['input']>;
  cancelButton?: InputMaybe<Scalars['String']['input']>;
  hasTextField?: InputMaybe<Scalars['Boolean']['input']>;
  keyboard?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  textField?: InputMaybe<Scalars['String']['input']>;
  textFieldInputId?: InputMaybe<Scalars['String']['input']>;
  textFieldPrompt?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GqlFormTemplateField = {
  __typename?: 'GqlFormTemplateField';
  attributes?: Maybe<GqlFormTemplateFieldAttribute>;
  columnStart?: Maybe<Scalars['String']['output']>;
  databinding?: Maybe<GqlFormTemplateFieldDatabinding>;
  fieldType?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<GqlFormTemplateField>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  ruleHooks?: Maybe<Array<FormRuleHookUnion>>;
  source?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type GqlFormTemplateFieldAttribute = {
  __typename?: 'GqlFormTemplateFieldAttribute';
  action?: Maybe<Scalars['String']['output']>;
  addButton?: Maybe<Scalars['Boolean']['output']>;
  addRowIf?: Maybe<Scalars['String']['output']>;
  alignment?: Maybe<Scalars['String']['output']>;
  analytics?: Maybe<Scalars['Boolean']['output']>;
  autoCapitalize?: Maybe<Scalars['String']['output']>;
  buttonStyle?: Maybe<Scalars['String']['output']>;
  checkbox?: Maybe<Scalars['String']['output']>;
  checkboxInputId?: Maybe<Scalars['String']['output']>;
  columnEnd?: Maybe<Scalars['Int']['output']>;
  columnStart?: Maybe<Scalars['Int']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  customItems?: Maybe<Scalars['Boolean']['output']>;
  divider?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  endId?: Maybe<Scalars['String']['output']>;
  endText?: Maybe<Scalars['String']['output']>;
  extendedText?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  hideTitle?: Maybe<Scalars['Boolean']['output']>;
  keyboardType?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  marginBottom?: Maybe<Scalars['String']['output']>;
  marginX?: Maybe<Scalars['String']['output']>;
  marginY?: Maybe<Scalars['String']['output']>;
  maxImages?: Maybe<Scalars['String']['output']>;
  nowrap?: Maybe<Scalars['Boolean']['output']>;
  nullDash?: Maybe<Scalars['Boolean']['output']>;
  outId?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  rowLimit?: Maybe<Scalars['Int']['output']>;
  showRequiredUser?: Maybe<Scalars['Boolean']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  startId?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  tagText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titleTextSize?: Maybe<Scalars['String']['output']>;
  titleTextWeight?: Maybe<Scalars['String']['output']>;
  validate?: Maybe<Scalars['String']['output']>;
  valueTextSize?: Maybe<Scalars['String']['output']>;
  valueTextWeight?: Maybe<Scalars['String']['output']>;
  visibleIf?: Maybe<Scalars['String']['output']>;
};

export type GqlFormTemplateFieldAttributeInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  addButton?: InputMaybe<Scalars['Boolean']['input']>;
  addRowIf?: InputMaybe<Scalars['String']['input']>;
  alignment?: InputMaybe<Scalars['String']['input']>;
  analytics?: InputMaybe<Scalars['Boolean']['input']>;
  autoCapitalize?: InputMaybe<Scalars['String']['input']>;
  buttonStyle?: InputMaybe<Scalars['String']['input']>;
  checkbox?: InputMaybe<Scalars['String']['input']>;
  checkboxInputId?: InputMaybe<Scalars['String']['input']>;
  columnEnd?: InputMaybe<Scalars['Int']['input']>;
  columnStart?: InputMaybe<Scalars['Int']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  customItems?: InputMaybe<Scalars['Boolean']['input']>;
  dayCounterId?: InputMaybe<Scalars['String']['input']>;
  divider?: InputMaybe<Scalars['Boolean']['input']>;
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  endId?: InputMaybe<Scalars['String']['input']>;
  endText?: InputMaybe<Scalars['String']['input']>;
  extendedText?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hideTitle?: InputMaybe<Scalars['Boolean']['input']>;
  keyboardType?: InputMaybe<Scalars['String']['input']>;
  layout?: InputMaybe<Scalars['String']['input']>;
  marginBottom?: InputMaybe<Scalars['String']['input']>;
  marginX?: InputMaybe<Scalars['String']['input']>;
  marginY?: InputMaybe<Scalars['String']['input']>;
  maxImages?: InputMaybe<Scalars['String']['input']>;
  nowrap?: InputMaybe<Scalars['Boolean']['input']>;
  nullDash?: InputMaybe<Scalars['Boolean']['input']>;
  outId?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  rowLimit?: InputMaybe<Scalars['Int']['input']>;
  showRequiredUser?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  startId?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
  tagText?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  titleTextSize?: InputMaybe<Scalars['String']['input']>;
  titleTextWeight?: InputMaybe<Scalars['String']['input']>;
  validate?: InputMaybe<Scalars['String']['input']>;
  valueTextSize?: InputMaybe<Scalars['String']['input']>;
  valueTextWeight?: InputMaybe<Scalars['String']['input']>;
  visibleIf?: InputMaybe<Scalars['String']['input']>;
};

export type GqlFormTemplateFieldDatabinding = {
  __typename?: 'GqlFormTemplateFieldDatabinding';
  autoSelect?: Maybe<Scalars['String']['output']>;
  expression?: Maybe<Scalars['String']['output']>;
  expressionVersion?: Maybe<Scalars['String']['output']>;
  onSelect?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type GqlFormTemplateFieldDatabindingInput = {
  autoSelect?: InputMaybe<Scalars['String']['input']>;
  expression?: InputMaybe<Scalars['String']['input']>;
  expressionVersion?: InputMaybe<Scalars['String']['input']>;
  onSelect?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GqlFormTemplateFieldInput = {
  attributes?: InputMaybe<GqlFormTemplateFieldAttributeInput>;
  columnStart?: InputMaybe<Scalars['String']['input']>;
  databinding?: InputMaybe<GqlFormTemplateFieldDatabindingInput>;
  fieldType?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<GqlFormTemplateFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  ruleHooks?: InputMaybe<Array<FormRuleHookInput>>;
  source?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GqlFormTemplateInput = {
  accountId: Scalars['String']['input'];
  alerts?: InputMaybe<Array<GqlFormTemplateAlertInput>>;
  androidLayout?: InputMaybe<GqlFormTemplateLayoutInput>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<GqlFormTemplateFieldInput>>;
  flagForReview?: InputMaybe<Scalars['Boolean']['input']>;
  formVersion: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  logicRules?: InputMaybe<Array<FormTemplateRuleInput>>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<GqlFormTemplateParametersInput>;
  renderEngineVersion: Scalars['String']['input'];
  subForms?: InputMaybe<Array<GqlSubFormTemplateInput>>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
  templateVersion: Scalars['String']['input'];
  webLayout?: InputMaybe<GqlFormTemplateLayoutInput>;
};

export type GqlFormTemplateLayout = {
  __typename?: 'GqlFormTemplateLayout';
  downloadName?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<GqlFormTemplateLayoutFields>>;
};

export type GqlFormTemplateLayoutFields = {
  __typename?: 'GqlFormTemplateLayoutFields';
  columns?: Maybe<Scalars['Int']['output']>;
  custom?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<GqlFormTemplateField>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GqlFormTemplateLayoutFieldsInput = {
  columns?: InputMaybe<Scalars['Int']['input']>;
  custom?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<GqlFormTemplateFieldInput>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type GqlFormTemplateLayoutInput = {
  downloadName?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<GqlFormTemplateLayoutFieldsInput>>;
};

export type GqlFormTemplateParameters = {
  __typename?: 'GqlFormTemplateParameters';
  forDate?: Maybe<Scalars['DateTime']['output']>;
};

export type GqlFormTemplateParametersInput = {
  forDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GqlFormTemplateResponse = {
  __typename?: 'GqlFormTemplateResponse';
  code: Scalars['Int']['output'];
  formTemplate?: Maybe<GqlFormTemplate>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GqlFormType = {
  __typename?: 'GqlFormType';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  isGlobal: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GqlFormTypeAccount = {
  __typename?: 'GqlFormTypeAccount';
  accountId: Scalars['String']['output'];
  branchIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  divisionIds?: Maybe<Array<Scalars['String']['output']>>;
  formType: GqlFormType;
  formTypeDisplayName: Scalars['String']['output'];
  formTypeSlug: Scalars['String']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GqlJob = {
  __typename?: 'GqlJob';
  accountId: Scalars['String']['output'];
  address?: Maybe<GqlAddress>;
  branchId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customer?: Maybe<GqlCustomer>;
  divisionId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  jobType?: Maybe<JobTypeEnum>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  projectOwner?: Maybe<Array<Scalars['String']['output']>>;
  salesPerson?: Maybe<Array<Scalars['String']['output']>>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<JobStatusEnum>;
  tasks?: Maybe<Array<GqlTask>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GqlJobDocument = {
  __typename?: 'GqlJobDocument';
  accountId: Scalars['String']['output'];
  active?: Maybe<Scalars['Boolean']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isMobileViewable?: Maybe<Scalars['Boolean']['output']>;
  jobId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sizeBytes: Scalars['Int']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type GqlJobOperatingCost = {
  __typename?: 'GqlJobOperatingCost';
  assignedAssetCost?: Maybe<Scalars['Float']['output']>;
  assignedCrewCost?: Maybe<Scalars['Float']['output']>;
  projectedTotalCost: Scalars['Float']['output'];
  projectedTotalRevenue?: Maybe<Scalars['Float']['output']>;
  quotedCost?: Maybe<Scalars['Float']['output']>;
};

export type GqlJobSystemCheck = {
  __typename?: 'GqlJobSystemCheck';
  environment: Scalars['JSON']['output'];
  featureFlags?: Maybe<Scalars['JSON']['output']>;
  launchDarklyStatus?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  sqlStatus?: Maybe<Scalars['String']['output']>;
};

export type GqlPriceBookItem = {
  __typename?: 'GqlPriceBookItem';
  accountId: Scalars['String']['output'];
  active: Scalars['Boolean']['output'];
  branchIds?: Maybe<Array<Scalars['String']['output']>>;
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultValue?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  divisionIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  rate: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GqlSubFormTemplate = {
  __typename?: 'GqlSubFormTemplate';
  alerts?: Maybe<Array<GqlFormTemplateAlert>>;
  fields?: Maybe<Array<GqlFormTemplateField>>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parameters?: Maybe<GqlFormTemplateParameters>;
};

export type GqlSubFormTemplateInput = {
  alerts?: InputMaybe<Array<GqlFormTemplateAlertInput>>;
  fields?: InputMaybe<Array<GqlFormTemplateFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<GqlFormTemplateParametersInput>;
};

export type GqlTask = {
  __typename?: 'GqlTask';
  accountId: Scalars['String']['output'];
  active?: Maybe<Scalars['Boolean']['output']>;
  assets?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crew?: Maybe<Array<Scalars['String']['output']>>;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  jobId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GqlTimecardSystemCheck = {
  __typename?: 'GqlTimecardSystemCheck';
  message: Scalars['String']['output'];
};

export type GqlUpdateFormTemplateInput = {
  alerts?: InputMaybe<Array<InputMaybe<GqlFormTemplateAlertInput>>>;
  androidLayout?: InputMaybe<GqlFormTemplateLayoutInput>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<GqlFormTemplateFieldInput>>>;
  flagForReview?: InputMaybe<Scalars['Boolean']['input']>;
  formVersion?: InputMaybe<Scalars['String']['input']>;
  logicRules?: InputMaybe<Array<FormTemplateRuleInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<GqlFormTemplateParametersInput>;
  renderEngineVersion?: InputMaybe<Scalars['String']['input']>;
  subForms?: InputMaybe<Array<InputMaybe<GqlSubFormTemplateInput>>>;
  submitted?: InputMaybe<Scalars['Boolean']['input']>;
  templateVersion?: InputMaybe<Scalars['String']['input']>;
  webLayout?: InputMaybe<GqlFormTemplateLayoutInput>;
};

export type GqlUserSystemCheck = {
  __typename?: 'GqlUserSystemCheck';
  environment: Scalars['JSON']['output'];
  featureFlags?: Maybe<Scalars['JSON']['output']>;
  launchDarklyStatus?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  minimumAndroidVersion?: Maybe<Scalars['String']['output']>;
  minimumIOSVersion?: Maybe<Scalars['String']['output']>;
  sqlStatus?: Maybe<Scalars['String']['output']>;
};

export type JobDocumentResponse = {
  __typename?: 'JobDocumentResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** The affected jobDocument object */
  jobDocument?: Maybe<GqlJobDocument>;
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export type JobResponse = {
  __typename?: 'JobResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** The affected job object */
  job?: Maybe<GqlJob>;
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export enum JobStatusEnum {
  Complete = 'complete',
  Ongoing = 'ongoing',
  Opportunity = 'opportunity',
  Scheduled = 'scheduled'
}

export enum JobTypeEnum {
  Maintenance = 'MAINTENANCE',
  Operations = 'OPERATIONS',
  Rental = 'RENTAL',
  Transport = 'TRANSPORT',
  Warehouse = 'WAREHOUSE'
}

export type ListFormSubmissionsInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  dynamicFilters?: InputMaybe<Scalars['JSON']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  formId: Scalars['ID']['input'];
  local?: InputMaybe<Scalars['Boolean']['input']>;
  nextPageKey?: InputMaybe<Scalars['ID']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderEnum>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ListJobDocumentsInput = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  isMobileViewable?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['String']['input'];
};

export type ListJobsInput = {
  branchId?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ListPriceBookItemsInput = {
  branchId?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createFormPDFConfig?: Maybe<GqlFormPdfConfigResponse>;
  createFormSubmission?: Maybe<GqlFormSubmissionResponse>;
  createFormTemplate?: Maybe<GqlFormTemplateResponse>;
  /** Creates a new job */
  createJob: JobResponse;
  /** Create a new job document */
  createJobDocument: JobDocumentResponse;
  /** Create a new price book item */
  createPriceBookItem: PriceBookItemResponse;
  /** Delete a job */
  deleteJob: DeleteJobResponse;
  /** Delete a job document */
  deleteJobDocument: DeleteJobDocumentResponse;
  /** Delete a price book item */
  deletePriceBookItem: DeletePriceBookItemResponse;
  updateFormTemplate?: Maybe<GqlFormTemplateResponse>;
  /** Update an existing job */
  updateJob: JobResponse;
  /** Update the dates of a job */
  updateJobDates: JobResponse;
  /** Update an existing job document */
  updateJobDocument: JobDocumentResponse;
};


export type MutationCreateFormPdfConfigArgs = {
  input: GqlFormPdfConfigInput;
};


export type MutationCreateFormSubmissionArgs = {
  input: GqlCreateFormSubmissionInput;
};


export type MutationCreateFormTemplateArgs = {
  input: GqlFormTemplateInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobDocumentArgs = {
  input: CreateJobDocumentInput;
};


export type MutationCreatePriceBookItemArgs = {
  input: CreatePriceBookItemInput;
};


export type MutationDeleteJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJobDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePriceBookItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateFormTemplateArgs = {
  id: Scalars['ID']['input'];
  input: GqlUpdateFormTemplateInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateJobDatesArgs = {
  input: UpdateJobDatesInput;
};


export type MutationUpdateJobDocumentArgs = {
  input: UpdateJobDocumentInput;
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PriceBookItemResponse = {
  __typename?: 'PriceBookItemResponse';
  /** Similar to HTTP status code, represents the status of the mutation */
  code: Scalars['Int']['output'];
  /** Human-readable message for the UI */
  message: Scalars['String']['output'];
  /** The affected jobDocument object */
  priceBookItem?: Maybe<GqlPriceBookItem>;
  /** Indicates whether the mutation was successful */
  success: Scalars['Boolean']['output'];
};

export type PublicUserServiceSettings = {
  __typename?: 'PublicUserServiceSettings';
  minimumAndroidVersion?: Maybe<Scalars['String']['output']>;
  minimumIOSVersion?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  assetSystemCheck: GqlAssetSystemCheck;
  formPDFConfig?: Maybe<GqlFormPdfConfig>;
  formSubmission?: Maybe<GqlFormSubmission>;
  formSubmissions: Array<GqlFormSubmission>;
  formTemplate?: Maybe<GqlFormTemplate>;
  formTemplates?: Maybe<Array<Maybe<GqlFormTemplate>>>;
  job?: Maybe<GqlJob>;
  jobDocuments?: Maybe<Array<Maybe<GqlJobDocument>>>;
  jobOperatingCost?: Maybe<GqlJobOperatingCost>;
  jobSystemCheck: GqlJobSystemCheck;
  jobs?: Maybe<Array<Maybe<GqlJob>>>;
  priceBookItems?: Maybe<Array<Maybe<GqlPriceBookItem>>>;
  /** Public facing user-service settings */
  publicUserServiceSettings: PublicUserServiceSettings;
  task?: Maybe<GqlTask>;
  timecardSystemCheck: GqlTimecardSystemCheck;
  userSystemCheck: GqlUserSystemCheck;
};


export type QueryAssetSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};


export type QueryFormPdfConfigArgs = {
  formId: Scalars['ID']['input'];
};


export type QueryFormSubmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormSubmissionsArgs = {
  filters: ListFormSubmissionsInput;
};


export type QueryFormTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormTemplatesArgs = {
  accountId: Scalars['ID']['input'];
};


export type QueryJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobDocumentsArgs = {
  filters: ListJobDocumentsInput;
};


export type QueryJobOperatingCostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};


export type QueryJobsArgs = {
  filters: ListJobsInput;
};


export type QueryPriceBookItemsArgs = {
  filters: ListPriceBookItemsInput;
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserSystemCheckArgs = {
  input?: InputMaybe<SystemCheckInput>;
};

export type SystemCheckInput = {
  checkLaunchDarkly?: InputMaybe<Scalars['Boolean']['input']>;
  checkSQL?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateJobDatesInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateJobDocumentInput = {
  accountId: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isMobileViewable?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sizeBytes?: InputMaybe<Scalars['Int']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobInput = {
  address?: InputMaybe<AddressInput>;
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  branchId?: InputMaybe<Scalars['String']['input']>;
  crew?: InputMaybe<Array<Scalars['String']['input']>>;
  customer?: InputMaybe<CustomerInput>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jobType?: InputMaybe<JobTypeEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  projectOwner?: InputMaybe<Array<Scalars['String']['input']>>;
  salesPerson?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePriceBookItemMutationVariables = Exact<{
  input: CreatePriceBookItemInput;
}>;


export type CreatePriceBookItemMutation = { __typename?: 'Mutation', createPriceBookItem: { __typename?: 'PriceBookItemResponse', success: boolean } };

export type DeletePriceBookItemMutationVariables = Exact<{
  deletePriceBookItemId: Scalars['ID']['input'];
}>;


export type DeletePriceBookItemMutation = { __typename?: 'Mutation', deletePriceBookItem: { __typename?: 'DeletePriceBookItemResponse', success: boolean } };

export type CreateFormPdfConfigMutationVariables = Exact<{
  input: GqlFormPdfConfigInput;
}>;


export type CreateFormPdfConfigMutation = { __typename?: 'Mutation', createFormPDFConfig?: { __typename?: 'GqlFormPDFConfigResponse', code: number, success: boolean, message: string } | null };

export type FormPdfConfigQueryVariables = Exact<{
  formId: Scalars['ID']['input'];
}>;


export type FormPdfConfigQuery = { __typename?: 'Query', formPDFConfig?: { __typename?: 'GqlFormPDFConfig', cssTemplate?: string | null, hbsTemplate: string, fields: Array<{ __typename?: 'GqlFormPDFConfigField', fieldId: string, format: FormPdfConfigFieldFormat, label: string, fields?: Array<{ __typename?: 'GqlFormPDFConfigField', fieldId: string, format: FormPdfConfigFieldFormat, label: string } | null> | null }> } | null };

export type PriceBookItemsQueryVariables = Exact<{
  filters: ListPriceBookItemsInput;
}>;


export type PriceBookItemsQuery = { __typename?: 'Query', priceBookItems?: Array<{ __typename?: 'GqlPriceBookItem', id: string, name: string, description?: string | null, branchIds?: Array<string> | null, divisionIds?: Array<string> | null, rate: number, unit: string, category?: string | null } | null> | null };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const CreatePriceBookItemDocument = new TypedDocumentString(`
    mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {
  createPriceBookItem(input: $input) {
    success
  }
}
    `) as unknown as TypedDocumentString<CreatePriceBookItemMutation, CreatePriceBookItemMutationVariables>;
export const DeletePriceBookItemDocument = new TypedDocumentString(`
    mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {
  deletePriceBookItem(id: $deletePriceBookItemId) {
    success
  }
}
    `) as unknown as TypedDocumentString<DeletePriceBookItemMutation, DeletePriceBookItemMutationVariables>;
export const CreateFormPdfConfigDocument = new TypedDocumentString(`
    mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {
  createFormPDFConfig(input: $input) {
    code
    success
    message
  }
}
    `) as unknown as TypedDocumentString<CreateFormPdfConfigMutation, CreateFormPdfConfigMutationVariables>;
export const FormPdfConfigDocument = new TypedDocumentString(`
    query FormPDFConfig($formId: ID!) {
  formPDFConfig(formId: $formId) {
    cssTemplate
    hbsTemplate
    fields {
      fieldId
      format
      label
      fields {
        fieldId
        format
        label
      }
    }
  }
}
    `) as unknown as TypedDocumentString<FormPdfConfigQuery, FormPdfConfigQueryVariables>;
export const PriceBookItemsDocument = new TypedDocumentString(`
    query PriceBookItems($filters: ListPriceBookItemsInput!) {
  priceBookItems(filters: $filters) {
    id
    name
    description
    branchIds
    divisionIds
    rate
    unit
    category
  }
}
    `) as unknown as TypedDocumentString<PriceBookItemsQuery, PriceBookItemsQueryVariables>;