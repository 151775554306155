import * as React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { AssetOverviewImages } from "./AssetOverviewImages";
import { Button } from "@/UI/Button";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useCategories } from "@/lib/react-query/queryHooks/useCategories";
import { useNavigate } from "react-router-dom";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { AssetsAsset } from "@/types/assetInventory/assets/general";
import { AssetOverviewDetailField } from ".";
import { Divider } from "@/UI/Divider";
import { PDFIcon, PagesIcon } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { AssetOverviewMap } from "./AssetOverviewMap";
import { useAuthContext } from "@/components/Auth/AuthWrapper";

interface AssetOverviewProps {
  assetData: AssetsAsset;
}

export const AssetOverview: React.FC<AssetOverviewProps> = ({ assetData }) => {
  const navigate = useNavigate();
  const { hasAssetCrud, hasRibbiotAdmin } = useAuthContext();
  const { data: categoriesData } = useCategories();
  const { data: branchData } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: divisionData } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const divisionName = divisionData?.data.find(
    (division) => division.id == assetData?.divisionId
  )?.divisionName;

  const branchNames = branchData?.data
    .filter((branch) => assetData?.branchIds?.includes(branch.id))
    .map((branch) => branch.name);

  const getBreadcrumb = () => {
    const path = categoriesData?.data.find(
      (category) => category.id === assetData?.categoryId
    )?.path;
    const nameArray = path?.split(">").map((pathId) => {
      return categoriesData?.data.find((category) => category.id === pathId)
        ?.name;
    });
    return nameArray?.join(" > ");
  };
  const images =
    assetData?.attachments
      .filter((at) => at.url.split("/").includes("images"))
      .sort((a, b) => {
        if (a.primary) return -1;
        if (b.primary) return 1;
        return 0;
      }) ?? [];

  const documents = assetData?.attachments.filter(
    (attachment) => !attachment.url.split("/").includes("images")
  );
  return (
    <section className="flex w-full justify-center gap-4 pt-10">
      <div className="max-w-[800px] basis-2/3 space-y-4 rounded-xl">
        <div className="flex h-[400px] rounded-xl border-2 bg-[#f2f7f9]">
          <AssetOverviewImages images={images} />
        </div>
        <div className="rounded-xl border-2 bg-white">
          <header className="flex items-start justify-between rounded-t-lg bg-[#f2f7f9] p-6">
            <div>
              <h3 className="text-base font-semibold text-theme-black-secondary">
                {getBreadcrumb()}
              </h3>
              <h1 className="my-1 text-4xl font-semibold">
                {assetData.assetName}
              </h1>
            </div>
            {(hasAssetCrud || hasRibbiotAdmin) && (
              <Button
                label="Edit"
                variant="primary"
                className="rounded-lg"
                onClick={() =>
                  navigate(`/assets/asset-detail?assetId=${assetData.id}`)
                }
              />
            )}
          </header>
          <div className="px-4">
            <h4 className="my-4 font-semibold">Asset Details</h4>
            <Divider />
            <div className="grid grid-cols-3 gap-4 py-4">
              <AssetOverviewDetailField
                title="Type"
                value={assetData.details?.assetType}
              />
              <AssetOverviewDetailField
                title="Category"
                value={assetData.category}
              />
              <AssetOverviewDetailField
                title="Manufacturer"
                value={assetData.details?.manufacturer}
              />
              <AssetOverviewDetailField title="Division" value={divisionName} />
              <AssetOverviewDetailField
                title="Branch"
                value={branchNames?.length ? branchNames.join(",") : undefined}
              />
              <AssetOverviewDetailField
                title="Serial Number"
                value={assetData.details?.serialNumber}
              />
            </div>
            <Divider />
          </div>
          <div className="px-4">
            <h4 className="my-1 font-semibold">Custom Details</h4>
            <Divider />
            <div className="grid grid-cols-3 gap-4 py-4">
              {assetData?.metadata && assetData?.metadata?.length < 1 && <>—</>}
              {assetData.metadata?.map((meta) => (
                <AssetOverviewDetailField
                  key={`${meta.metadataName} + ${meta.metadataValue}`}
                  title={meta.metadataName}
                  value={`${meta.metadataValue} ${
                    meta?.metadataUnitOfMeasure ?? ""
                  }`}
                />
              ))}
            </div>
            <Divider />
          </div>
        </div>
      </div>
      <aside className="basis-1/3 space-y-4 rounded-xl">
        <AssetOverviewMap assetData={assetData} />
        <div className="h-80 overflow-auto rounded-xl border-2 border-gray-200">
          <div className="sticky top-0 flex justify-between  border-b-2 bg-[#f2f7f9] px-4 py-2">
            <h5 className="font-semibold">Asset Documents</h5>
            {documents.length > 0 && (
              <button
                onClick={() =>
                  navigate(`/assets/asset-documents?assetId=${assetData.id}`)
                }
                className="text-xs text-theme-green-primary"
              >
                View All ({documents?.length}) »
              </button>
            )}
          </div>
          <ul className="mx-2">
            {!documents ||
              (documents.length < 1 && (
                <div className="flex h-60 w-full items-center justify-center">
                  <p className="m-auto">No Documents Found</p>
                </div>
              ))}
            {documents?.map((document) => (
              <li key={document.id} className={classNames("border-b-2 py-1 ")}>
                <div className="justify-between9 group flex h-10 w-full items-center hover:bg-[#E9F2F5]">
                  <a
                    className="my-2 flex w-full  overflow-hidden text-ellipsis whitespace-nowrap px-2 text-base font-medium text-palette-black"
                    href={document.url}
                    rel="noreferrer"
                    target="_blank"
                    onClick={() => {
                      amplitude.track(AMPLITUDE_EVENTS.ASSET_DOCUMENT_OPENED, {
                        asset_name: assetData?.assetName || "",
                        document_name: document.name || "",
                        document_file_type: document.contentType || "",
                      });
                    }}
                  >
                    {document.contentType === "pdf" ? (
                      <PDFIcon />
                    ) : (
                      <PagesIcon fill="#000" />
                    )}
                    <span className="mx-3">{document.name ?? "Document"}</span>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </section>
  );
};
