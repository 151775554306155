import { Button, ButtonVariant } from "../Button";
import { createPortal } from "react-dom";
import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, ReactNode, useEffect, useRef } from "react";
import { classNames } from "@/utils/helpers/classNames";

type ModalProps = {
  beforeEnter?: () => void;
  beforeLeave?: () => void;
  cancelButtonText?: string;
  children: ReactNode;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  innerContainerClasses?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  open: boolean;
  submit?: boolean;
  subtitle?: string;
  title?: string;
  topClose?: boolean;
  topCloseButtonClasses?: string;
  topCloseContainerClasses?: string;
};

export const Modal: FC<ModalProps> = ({
  beforeEnter,
  beforeLeave,
  cancelButtonText = "Cancel",
  children,
  confirmButtonText = "Confirm",
  confirmButtonVariant = "primary",
  disableCancel = false,
  disableConfirm = false,
  hideButtons = false,
  hideCancelButton,
  innerContainerClasses,
  onCancel,
  onConfirm,
  open,
  submit,
  subtitle,
  title,
  topClose = false,
  topCloseButtonClasses = "",
  topCloseContainerClasses = "",
}) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  const cancelButtonRef = useRef(null);

  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    const modalRoot = document.getElementById("modal");
    if (modalRoot && elRef.current) {
      modalRoot.appendChild(elRef.current);
    }

    return () => {
      if (modalRoot && elRef.current) {
        modalRoot.removeChild(elRef.current);
      }
    };
  }, []);

  return createPortal(
    <Transition.Root
      show={open}
      as={Fragment}
      beforeEnter={beforeEnter}
      beforeLeave={beforeLeave}
    >
      <Dialog
        as="div"
        initialFocus={cancelButtonRef}
        className="fixed inset-0 z-50 overflow-y-scroll"
        onClose={() => null}
      >
        <div
          className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
          role="alertdialog"
          aria-labelledby="title"
          aria-describedby="bodyText"
          aria-modal="true"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#151B22] bg-opacity-[0.85]  backdrop-blur-[10px] transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden align-middle sm:inline-block sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                `& inline-block max-w-[984px] transform rounded-lg bg-white p-10 text-left align-bottom shadow-xl
                transition-all sm:my-8 sm:w-full sm:align-middle`,
                innerContainerClasses
              )}
              id="modal-content"
            >
              {topClose && (
                <div
                  className={classNames(
                    "absolute right-[-4px] top-[-3px]",
                    topCloseContainerClasses
                  )}
                >
                  <button
                    className={classNames(
                      "full h-8 w-8 rounded-full border bg-palette-companyGreen font-semibold text-white",
                      topCloseButtonClasses
                    )}
                    onClick={onCancel}
                  >
                    <span>X</span>
                  </button>
                </div>
              )}

              {title && (
                <p>
                  <span className="text-4xl font-semibold">{title}</span>
                </p>
              )}

              {subtitle && (
                <p className="mt-4">
                  <span className="text-lg font-medium">{subtitle}</span>
                </p>
              )}

              <div
                className={classNames(
                  "h-full w-full",
                  !hideButtons ? "mb-16" : "mb-0"
                )}
              >
                {children}
              </div>

              {!hideButtons && (
                <div className="flex justify-end gap-x-5">
                  {!hideCancelButton && (
                    <Button
                      disabled={disableCancel}
                      label={cancelButtonText}
                      variant="secondary"
                      onClick={onCancel}
                      className="rounded-md"
                    />
                  )}
                  <Button
                    disabled={disableConfirm}
                    label={confirmButtonText}
                    variant={confirmButtonVariant}
                    onClick={onConfirm}
                    type={submit ? "submit" : "button"}
                    className="rounded-md"
                  />
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    elRef.current
  );
};
