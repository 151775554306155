import { Button } from "@/UI/Button";
import * as amplitude from "@amplitude/analytics-browser";
import { DangerModal } from "@/UI/Modal";
import { Table } from "@/components/Table";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useDeleteAttachment } from "@/lib/react-query/mutationHooks/useDeleteAttachment";
import { useAsset } from "@/lib/react-query/queryHooks/useAsset";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { AssetsAsset } from "@/types/assetInventory/assets/general";
import { getAssetDocumentsColumns } from "@/utils/columnDefinitions/assetDocumentsColumns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { useUploadAssetDocument } from "@/lib/react-query/mutationHooks/useUploadAssetDocument";
import { useCreateAttachment } from "@/lib/react-query/mutationHooks/useCreateAttachment";
import { useAuthContext } from "@/components/Auth/AuthWrapper";

export const AssetDocuments = () => {
  const navigate = useNavigate();
  const { hasAssetCrud, hasRibbiotAdmin } = useAuthContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();

  const {
    data: useAssetData,
    isError,
    isLoading,
  } = useAsset({
    assetId,
    options: { staleTime: 5 * 1000 * 60 },
  });
  if (!assetId) navigate("/assets/browser");

  useEffect(() => {
    if (isError) {
      setNotFound({ notFound: true, message: "No Asset Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const assetData = useAssetData?.asset;

  const documents = assetData?.attachments.filter(
    (attachment) => !attachment.url.split("/").includes("images")
  );

  const handleDeleteAssetDocument = (id: string) => {
    setDeleteDocumentModal({
      id,
      open: true,
    });
  };

  const [deleteDocumentModal, setDeleteDocumentModal] = useState<{
    open: boolean;
    id: string;
  }>({ open: false, id: "" });

  const {
    mutateAsync: createAttachmentAsync,
    isPending: createAttachmentLoading,
  } = useCreateAttachment();
  const {
    mutateAsync: uploadAssetDocumentAsync,
    isPending: uploadAssetDocumentLoading,
  } = useUploadAssetDocument();
  const {
    mutateAsync: deleteAttachmentAsync,
    isPending: deleteAttachmentLoading,
  } = useDeleteAttachment();

  const deleteAttachment = async (attachmentId: string) => {
    setDeleteDocumentModal({ id: "", open: false });
    if (!assetData) return;
    try {
      const {
        data: { asset: updatedAsset },
      } = await deleteAttachmentAsync({
        assetId: assetData.id,
        attachmentId,
        categoryId: assetData.categoryId,
      });

      toast.success("Attachment deleted.");
      await handleCacheProcess(updatedAsset);
      setDeleteDocumentModal({ id: "", open: false });
    } catch (error) {
      toast.error("Failed to delete attachment.");
    }
  };

  const handleCacheProcess = async (asset: AssetsAsset) => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.ASSET, { assetId: asset.id }],
      type: "all",
    });
  };

  const uploadDocument = async (document: {
    mime: string;
    document: string | ArrayBuffer | null | undefined;
    name: string;
  }) => {
    try {
      const { data } = await uploadAssetDocumentAsync({
        document,
      });

      if (assetData) {
        try {
          const {
            data: { asset: updatedAsset },
          } = await createAttachmentAsync({
            assetId: assetData.id,
            body: {
              url: data.Location,
              contentType: data.Extension,
              primary: false,
            },
            categoryId: assetData.categoryId,
            name: document.name,
          });

          await handleCacheProcess(updatedAsset);

          amplitude.track(AMPLITUDE_EVENTS.DOCUMENT_ADDED, {
            asset_name: assetData.assetName,
          });

          toast.success("Document added to attachments.");
        } catch (error) {
          toast.error("Failed to upload document.");
        }
      }
    } catch (error) {
      toast.error(
        "Failure uploading document. File size limit exceeded or network error occurred."
      );
    }
  };
  const selectDocument = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/*";
    input.addEventListener("change", (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        await uploadDocument({
          mime: selection?.type || "",
          document: event?.target?.result,
          name: selection?.name ?? "",
        });
      };
      reader.readAsDataURL(selection!);
    });
    input.click();

    // Clean up
    input.remove();
  };

  const loading =
    createAttachmentLoading ||
    uploadAssetDocumentLoading ||
    deleteAttachmentLoading;
  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-8 flex w-full justify-between">
        <h1 className="text-4xl font-semibold">Asset Documents</h1>
        {(hasAssetCrud || hasRibbiotAdmin) && (
          <Button
            className="!rounded-full "
            label="+ Add New Document"
            onClick={selectDocument}
            disabled={loading}
            loading={loading}
            variant="primary"
          />
        )}
      </header>

      <div className="h-[calc(100%-72px)] w-full overflow-auto rounded-md border bg-white">
        <Table
          clickableRow
          emptyMessage="No asset documents found."
          loading={isLoading}
          tableColumns={getAssetDocumentsColumns({ handleDeleteAssetDocument })}
          tableData={documents ?? []}
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          trClasses="hover:bg-[#e9f2f4] !py-4"
          onRowClick={(row) => {
            if (!row?.original?.url) return;
            amplitude.track(AMPLITUDE_EVENTS.ASSET_DOCUMENT_OPENED, {
              asset_name: assetData?.assetName || "",
              document_name: row.original.name || "",
              document_file_type: row.original.contentType || "",
            });
            window.open(row.original.url, "_blank")?.focus();
          }}
          visibilityState={{
            actions: hasAssetCrud || hasRibbiotAdmin ? true : false,
          }}
        />
      </div>

      <DangerModal
        cancelAction={() => setDeleteDocumentModal({ id: "", open: false })}
        cancelButtonDisabled={deleteAttachmentLoading}
        confirmAction={() => deleteAttachment(deleteDocumentModal.id)}
        confirmButtonDisabled={deleteAttachmentLoading}
        message="Are you sure you want to delete this document? This action cannot be undone."
        open={deleteDocumentModal.open}
        title="Delete Document"
      />
    </div>
  );
};
