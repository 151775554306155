/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n": types.CreatePriceBookItemDocument,
    "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n": types.DeletePriceBookItemDocument,
    "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n": types.CreateFormPdfConfigDocument,
    "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n": types.FormPdfConfigDocument,
    "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      name\n      description\n      branchIds\n      divisionIds\n      rate\n      unit\n      category\n    }\n  }\n": types.PriceBookItemsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n"): typeof import('./graphql').CreatePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n"): typeof import('./graphql').DeletePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n"): typeof import('./graphql').CreateFormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').FormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      name\n      description\n      branchIds\n      divisionIds\n      rate\n      unit\n      category\n    }\n  }\n"): typeof import('./graphql').PriceBookItemsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
